<template>
<div>
<h2 class="page-title-bar"><i class="ico ico-system"></i>{{$t('Product File Config')}}</h2>
<div>
<v-row style="margin-top:20px">
  <v-col cols="12" sm="6">
   <div>
                <!-- <h4
                  class="tit-section-sub"
                  style="background: #e2e2e2 !important"
                >
                  {{ $t("Product File Reading") }}
                </h4> -->
          <h3 class="tit-group-bg" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Product File Reading')}} *</h3>

              </div>
    <div>
      <v-row>
        <v-col cols="12" sm="4">
        <v-select :items="getEntension" @change="checkExtension($event)" :item-text="'name'" :item-value="'name'" v-model="selExntension" :label="$t('File Extension')" placeholder="Select file extension" class="form-select " outlined dense>
         </v-select>
        </v-col>

        <v-col cols="12" sm="4">
        <v-text-field class=" form-input " v-model="extTyping"  dense outlined hide-details   :disabled="extreadonly"></v-text-field>
        </v-col>
      </v-row>
       <v-row>
        <v-col cols="12" sm="4">
        <v-select :items="getDelimiter" @change="checkDelimiters($event)" :item-text="'name'" :item-value="'name'" v-model="selDelemiters" :label="$t('Delimiter')" placeholder="Select delimiter"  class="form-select " outlined dense>
         </v-select>
        </v-col>

        <v-col cols="12" sm="4">
        <v-text-field class="form-input " v-model="delTyping"   dense outlined  hide-details  :disabled='delreadonly'></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
<v-text-field
            v-model="fileuploadpath"
            :label="$t('Uploaded Folder Path')"
            :placeholder="`${$t('Set product file upload path')}`"
            class="form-input"
            outlined
            dense
            hide-details
            clearable
            @keydown="clearTabindex"

    ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
            <v-text-field
            v-model="filebackuppath"
           :label="$t('Backup Folder Path')"
           :placeholder="`${$t('Set product file backup path')}`"
            class="form-input"
            outlined
            dense
            hide-details
            clearable
            @keydown="clearTabindex"
    ></v-text-field>

        </v-col>
      </v-row>
      <v-row style="margin-top:30px;">
          <v-col cols="12" sm="3">
        <v-text-field :label="$t('File Reading Unit')" v-model="fileReadingUnit" value="1000" class="form-input "   dense outlined filled ></v-text-field>
          </v-col>
          <div style="margin-top:43px;">{{$t('rows')}}</div>
          <v-col cols="12" sm="3">
        <v-text-field :label="$t('Folder Check Delay')" v-model="folderCheckDelay" value="10000" class="form-input "   dense outlined filled ></v-text-field>
          </v-col>
                    <div style="margin-top:43px;">{{$t('millisecond')}}</div>

      </v-row>
      <v-row>
          <v-btn @click="saveFileReading" :disabled="btnDisabledDetail" class="btn  " text
        >{{ $t('Save') }}
      </v-btn>
        <div style="margin-top:10px;margin-left:12px;font-weight:600">
        <p> * {{$t('If these values are changed , AIMS service need to be restarted')}}</p>
      </div>
      </v-row>

     </div>
   </v-col>
<v-col cols="12" sm="6">
<div>
          <h3 class="tit-group-bg" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Field List')}}</h3>

              </div>
<fieldList/>
</v-col>
</v-row>
</div>
</div>
</template>
<script>

import EventBus from '@/plugins/eventBus'
import fieldList from './FieldList'
const requests = {
  postFileReading: {
    method: 'PUT',
    url: '/api/common/property'
  },
  getFileReading: {
    method: 'GET',
    url: '/api/common/property'
  }

}
export default {
  components: {
    fieldList
  },
  data () {
    return {
      isReadOnly: true,
      extreadonly: true,
      delreadonly: true,
      selected: [],
      extTyping: '',
      delTyping: '',
      selDelemiters: '',
      selExntension: '',
      fileuploadpath: '',
      filebackuppath: '',
      fileReadingUnit: '',
      folderCheckDelay: '',
      keyarray: [],
      btnDisabledDetail: null,
      showErrorMessage: false,
      getinitialData: [],

      loadkeyvalue: ['CUSTOMER_FILE_PATTERN', 'CUSTOMER_FILE_PARSER_DELIMITER', 'CUSTOMER_FILE_INPUT_PATH', 'CUSTOMER_FILE_BACKUP_PATH', 'CUSTOMER_FILE_NUMBER_OF_ROWS_PER_ONCE', 'CUSTOMER_FILE_FIXED_DELAY']
    }
  },
  computed: {

    getEntension () {
      return [
        { id: 1, name: '*.csv' },
        { id: 2, name: '*.dat' },
        { id: 3, name: '*.txt' },
        { id: 4, name: 'text typing' }
      ]
    },
    getDelimiter () {
      return [
        { id: 1, name: ',' },
        { id: 2, name: ';' },
        { id: 3, name: '|' },
        { id: 4, name: '/' },
        { id: 5, name: 'text typing' }
      ]
    }
  },
  mounted () {
    this.getFileReadingdata()
    // disable save option
    this.$store.dispatch('auth/getDisabledBtn', '9500').then(flag => {
      this.btnDisabledDetail = flag
    })
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    getFileReadingdata () {
      var config = {}
      this.$utils
        .callAxios(requests.getFileReading.method, requests.getFileReading.url, config).then(res => {
          this.getinitialData = res.data
          if (res.status === 200) {
            var getFileExtensionArr = this.getEntension
            var extposition = getFileExtensionArr.map(function (e) { return e.name }).indexOf(res.data.customerFilePattern)
            if (extposition !== -1) {
              this.selExntension = res.data.customerFilePattern
            } else {
              this.extTyping = res.data.customerFilePattern
              this.selExntension = 'text typing'
              this.extreadonly = false
            }
            var delitposition = this.getDelimiter.map(function (e) { return e.name }).indexOf(res.data.customerFileParserDelimiter)
            if (delitposition !== -1) {
              this.selDelemiters = res.data.customerFileParserDelimiter
            } else {
              this.delTyping = res.data.customerFileParserDelimiter
              this.selDelemiters = 'text typing'
              this.delreadonly = false
            }
            this.fileuploadpath = res.data.customerFileInputPath
            this.filebackuppath = res.data.customerFileBackupPath
            this.fileReadingUnit = res.data.customerFileNumberOfRowsPerOnce
            this.folderCheckDelay = res.data.customerFileFixedDelay
          }
        })
    },
    // file reading
    checkExtension (item) {
      if (item === 'text typing') {
        this.extreadonly = false
        this.extTyping = '*.'
      } else {
        this.extreadonly = true
        this.extTyping = ''
      }
    },
    checkDelimiters (item) {
      if (item === 'text typing') {
        this.delreadonly = false
      } else {
        this.delreadonly = true
        this.delTyping = ''
      }
    },

    async saveFileReading () {
      this.showErrorMessage = false
      if (this.fileuploadpath === '' || this.fileuploadpath === null || this.fileuploadpath === undefined ||
  this.filebackuppath === '' || this.filebackuppath === null || this.filebackuppath === undefined ||
  this.fileReadingUnit === '' || this.fileReadingUnit === null || this.fileReadingUnit === undefined ||
  this.folderCheckDelay === '' || this.folderCheckDelay === null || this.folderCheckDelay === undefined) {
        EventBus.$emit('messageAlert', this.$t('Please provide valid data'))
        return
      }
      var params = ''
      if (this.selExntension === 'text typing') {
        if (this.extTyping === '' || this.extTyping === null || this.extTyping === undefined || this.extTyping === '*.') {
          this.extTyping = '*.'
          EventBus.$emit('messageAlert', this.$t('Please provide valid data'))
          return
        }

        var customerFilePattern = this.extTyping
        params = { key: 'CUSTOMER_FILE_PATTERN', value: customerFilePattern }
        this.keyarray.push({ params })
      } else if (this.selExntension !== '' && this.selExntension !== 'text typing' && this.selExntension !== null) {
        var customerFilePattern1 = this.selExntension
        params = { key: 'CUSTOMER_FILE_PATTERN', value: customerFilePattern1 }
        this.keyarray.push({ params })
      }
      if (this.selDelemiters === 'text typing') {
        if (this.delTyping === '' || this.delTyping === null || this.delTyping === undefined) {
          EventBus.$emit('messageAlert', this.$t('Please provide valid data'))
          return
        }
        var customerFileParserDelimiter = this.delTyping
        params = { key: 'CUSTOMER_FILE_PARSER_DELIMITER', value: customerFileParserDelimiter }
        this.keyarray.push({ params })
      } else if (this.selDelemiters !== '' && this.selDelemiters !== 'text typing' && this.selDelemiters !== 'null') {
        var customerFileParserDelimiter1 = this.selDelemiters
        params = { key: 'CUSTOMER_FILE_PARSER_DELIMITER', value: customerFileParserDelimiter1 }
        this.keyarray.push({ params })
      }
      if (this.fileuploadpath !== '' && this.fileuploadpath !== null && this.fileuploadpath !== undefined) {
        // var setpath = '/inf/input'
        // setpath = this.fileuploadpath.trim()
        params = { key: 'CUSTOMER_FILE_INPUT_PATH', value: this.fileuploadpath.trim() }
        this.keyarray.push({ params })
      }
      if (this.filebackuppath !== '' && this.filebackuppath !== null && this.filebackuppath !== undefined) {
        // var setBackupPath = '/inf/backup'
        // setBackupPath = this.filebackuppath.trim()
        params = { key: 'CUSTOMER_FILE_BACKUP_PATH', value: this.filebackuppath.trim() }
        this.keyarray.push({ params })
      }
      if (this.fileReadingUnit !== '' && this.fileReadingUnit !== null && this.fileReadingUnit !== undefined) {
        params = { key: 'CUSTOMER_FILE_NUMBER_OF_ROWS_PER_ONCE', value: this.fileReadingUnit }
        this.keyarray.push({ params })
      } if (this.folderCheckDelay !== '' && this.folderCheckDelay !== null && this.folderCheckDelay !== undefined) {
        params = { key: 'CUSTOMER_FILE_FIXED_DELAY', value: this.folderCheckDelay }
        this.keyarray.push({ params })
      }

      const ApiCallArray = []
      for (let i = 0; i < this.keyarray.length; i++) {
        this.showErrorMessage = false
        const config = this.keyarray[i]
        ApiCallArray.push(
          this.$utils
            .callAxiosWithBody(requests.postFileReading.method, requests.postFileReading.url, {}, config)
            .catch(error => {
              this.showErrorMessage = true
              console.debug(error)

              if (this.keyarray[i].params.key === 'CUSTOMER_FILE_INPUT_PATH') {
                EventBus.$emit('messageAlert', this.$t('Uploaded folder path is incorrect'))
                this.keyarray = []
              } else if (this.keyarray[i].params.key === 'CUSTOMER_FILE_BACKUP_PATH') {
                EventBus.$emit('messageAlert', this.$t('Backup folder path is incorrect'))
                this.keyarray = []
              }
            })
        )
      }
      await Promise.all(ApiCallArray).then(res => {
        if (this.showErrorMessage !== true) {
          this.getFileReadingdata()
          this.keyarray = []
          if (res[0].status === 200) {
            EventBus.$emit('messageAlert', this.$t(res[0].data.responseMessage))
            this.selExntension = ''
            this.selDelemiters = ''
            this.extreadonly = true
            this.delreadonly = true
            this.fileuploadpath = null
            this.filebackuppath = null
            this.extTyping = ''
            this.delTyping = ''
          } else {
            EventBus.$emit('messageAlert', this.$t(res[0].data.responseMessage))
          }
        }
      }).catch(() => {
      })
    }

  }
}
</script>
<style scoped>

.tit-section{
background-image: linear-gradient(#ececec,#ececec);
color: black;
}
.btn{
    padding:10px !important
}
.btnclr{
    width: 100%;
    border:1px solid #e8e8e8;
    height:48px !important
}
.btnClose{
     width: 20%;
     height:48px !important
}

</style>
