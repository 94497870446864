var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700", scrollable: "", persistent: "" },
      model: {
        value: _vm.fieldMappingDialog,
        callback: function($$v) {
          _vm.fieldMappingDialog = $$v
        },
        expression: "fieldMappingDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-system" }),
              _vm._v(_vm._s(_vm.$t("Field Mapping")) + " ")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "tbl-type03",
                      staticStyle: {
                        "background-color": "#001e38",
                        color: "white",
                        "text-align": "center"
                      },
                      attrs: { cols: "12", sm: "6" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("MAPPED FIELD")))]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "tbl-type03",
                      staticStyle: {
                        "background-color": "#001e38",
                        color: "white",
                        "text-align": "center"
                      },
                      attrs: { cols: "12", sm: "6" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("SELECTED FIELD")))]
                  )
                ],
                1
              ),
              _vm._l(_vm.getInitialData, function(item) {
                return _c(
                  "v-row",
                  { key: item.id },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "padding-0",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btnClass",
                            staticStyle: { width: "100%", cursor: "default" }
                          },
                          [
                            _vm._v(_vm._s(item.name)),
                            item.clearable === false
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*")
                                ])
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "padding-0",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("v-autocomplete", {
                          staticClass: "grey lighten-2",
                          attrs: {
                            "item-text": "name",
                            "item-value": "id",
                            outlined: "",
                            "prepend-inner-icon": "mdi-search-web",
                            "hide-details": "",
                            items: _vm.valuePair,
                            clearable: item.clearable
                          },
                          on: {
                            change: function($event) {
                              return _vm.editFieldname(item, item.value)
                            },
                            keydown: _vm.clearTabindex
                          },
                          model: {
                            value: item.value,
                            callback: function($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.productFieldMapSettings === true
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.addPlusIcon, function(item, i) {
                        return _c(
                          "div",
                          { key: i, attrs: { value: item } },
                          [
                            _c(
                              "v-row",
                              { staticClass: "mt-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "padding-0",
                                    style:
                                      _vm.addPlusIcon.length - 1 == i
                                        ? "max-width: 48%"
                                        : "max-width: 50%",
                                    attrs: { cols: "12", sm: "6" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "btnClass",
                                        staticStyle: {
                                          width: "100%",
                                          cursor: "default"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.reservedListData[i].name)
                                        ),
                                        item.clearable === false
                                          ? _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "padding-0",
                                    style:
                                      _vm.addPlusIcon.length - 1 == i
                                        ? "max-width: 47%"
                                        : "max-width: 47%",
                                    attrs: { cols: "12", sm: "6" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "grey lighten-2",
                                      attrs: {
                                        outlined: "",
                                        "prepend-inner-icon": "mdi-search-web",
                                        "hide-details": "",
                                        items:
                                          _vm.getSelectedField
                                            .standardDataMapList,
                                        clearable: item.clearable
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.editFieldname(
                                            item,
                                            item.value
                                          )
                                        },
                                        keydown: _vm.clearTabindex
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.addPlusIcon.length - 1 == i
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticStyle: {
                                          "padding-right": "4px",
                                          height: "55px !important",
                                          width: "24px",
                                          "min-width": "1px !important",
                                          padding: "0 10px !important",
                                          "margin-top": "12px",
                                          "border-top-left-radius": "0px",
                                          "border-bottom-left-radius": "0px"
                                        },
                                        attrs: { color: "red", dark: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeReservedList(
                                              i,
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              "font-size": "17px"
                                            },
                                            attrs: { dark: "" }
                                          },
                                          [_vm._v("mdi-close")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm.addPlusIcon.length < 7
                        ? _c(
                            "v-btn",
                            {
                              staticStyle: {
                                width: "10%",
                                cursor: "default",
                                "margin-top": "20px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.plusIcon(_vm.addPlusIcon.length)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/ico-add.jpg"),
                                  alt: "Product ID input box add button"
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "btn",
                    attrs: {
                      text: "",
                      icon: "",
                      disabled: _vm.btnDisabledDetail
                    }
                  },
                  _vm.productFieldMapSettings === true
                    ? { click: _vm.saveFiledMappingMetro }
                    : { click: _vm.saveFiledMapping }
                ),
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.close }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }