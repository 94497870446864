var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "page-title-bar" }, [
      _c("i", { staticClass: "ico ico-system" }),
      _vm._v(_vm._s(_vm.$t("Product File Config")))
    ]),
    _c(
      "div",
      [
        _c(
          "v-row",
          { staticStyle: { "margin-top": "20px" } },
          [
            _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
              _c("div", [
                _c(
                  "h3",
                  {
                    staticClass: "tit-group-bg",
                    staticStyle: {
                      color: "rgba(0, 0, 0, 0.6)",
                      "line-height": "1"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Product File Reading")) + " *")]
                )
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("v-select", {
                            staticClass: "form-select ",
                            attrs: {
                              items: _vm.getEntension,
                              "item-text": "name",
                              "item-value": "name",
                              label: _vm.$t("File Extension"),
                              placeholder: "Select file extension",
                              outlined: "",
                              dense: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.checkExtension($event)
                              }
                            },
                            model: {
                              value: _vm.selExntension,
                              callback: function($$v) {
                                _vm.selExntension = $$v
                              },
                              expression: "selExntension"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("v-text-field", {
                            staticClass: " form-input ",
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              disabled: _vm.extreadonly
                            },
                            model: {
                              value: _vm.extTyping,
                              callback: function($$v) {
                                _vm.extTyping = $$v
                              },
                              expression: "extTyping"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("v-select", {
                            staticClass: "form-select ",
                            attrs: {
                              items: _vm.getDelimiter,
                              "item-text": "name",
                              "item-value": "name",
                              label: _vm.$t("Delimiter"),
                              placeholder: "Select delimiter",
                              outlined: "",
                              dense: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.checkDelimiters($event)
                              }
                            },
                            model: {
                              value: _vm.selDelemiters,
                              callback: function($$v) {
                                _vm.selDelemiters = $$v
                              },
                              expression: "selDelemiters"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("v-text-field", {
                            staticClass: "form-input ",
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              disabled: _vm.delreadonly
                            },
                            model: {
                              value: _vm.delTyping,
                              callback: function($$v) {
                                _vm.delTyping = $$v
                              },
                              expression: "delTyping"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              label: _vm.$t("Uploaded Folder Path"),
                              placeholder:
                                "" + _vm.$t("Set product file upload path"),
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.fileuploadpath,
                              callback: function($$v) {
                                _vm.fileuploadpath = $$v
                              },
                              expression: "fileuploadpath"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            staticClass: "form-input",
                            attrs: {
                              label: _vm.$t("Backup Folder Path"),
                              placeholder:
                                "" + _vm.$t("Set product file backup path"),
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              clearable: ""
                            },
                            on: { keydown: _vm.clearTabindex },
                            model: {
                              value: _vm.filebackuppath,
                              callback: function($$v) {
                                _vm.filebackuppath = $$v
                              },
                              expression: "filebackuppath"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-text-field", {
                            staticClass: "form-input ",
                            attrs: {
                              label: _vm.$t("File Reading Unit"),
                              value: "1000",
                              dense: "",
                              outlined: "",
                              filled: ""
                            },
                            model: {
                              value: _vm.fileReadingUnit,
                              callback: function($$v) {
                                _vm.fileReadingUnit = $$v
                              },
                              expression: "fileReadingUnit"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { "margin-top": "43px" } }, [
                        _vm._v(_vm._s(_vm.$t("rows")))
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-text-field", {
                            staticClass: "form-input ",
                            attrs: {
                              label: _vm.$t("Folder Check Delay"),
                              value: "10000",
                              dense: "",
                              outlined: "",
                              filled: ""
                            },
                            model: {
                              value: _vm.folderCheckDelay,
                              callback: function($$v) {
                                _vm.folderCheckDelay = $$v
                              },
                              expression: "folderCheckDelay"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { "margin-top": "43px" } }, [
                        _vm._v(_vm._s(_vm.$t("millisecond")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn  ",
                          attrs: { disabled: _vm.btnDisabledDetail, text: "" },
                          on: { click: _vm.saveFileReading }
                        },
                        [_vm._v(_vm._s(_vm.$t("Save")) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-left": "12px",
                            "font-weight": "600"
                          }
                        },
                        [
                          _c("p", [
                            _vm._v(
                              " * " +
                                _vm._s(
                                  _vm.$t(
                                    "If these values are changed , AIMS service need to be restarted"
                                  )
                                )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "v-col",
              { attrs: { cols: "12", sm: "6" } },
              [
                _c("div", [
                  _c(
                    "h3",
                    {
                      staticClass: "tit-group-bg",
                      staticStyle: {
                        color: "rgba(0, 0, 0, 0.6)",
                        "line-height": "1"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Field List")))]
                  )
                ]),
                _c("fieldList")
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }