var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hr"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticStyle: { "margin-left": "50px", "font-size": "14px" },
              attrs: { cols: "12", sm: "4" }
            },
            [_c("h5", [_c("b", [_vm._v(_vm._s(_vm.$t("FIELD ID")))])])]
          ),
          _c(
            "v-col",
            {
              staticStyle: { "font-size": "14px" },
              attrs: { cols: "12", sm: "6" }
            },
            [_c("h5", [_c("b", [_vm._v(_vm._s(_vm.$t("FIELD NAME")))])])]
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "v-layout",
        { staticStyle: { height: "460px" }, attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticStyle: { "overflow-y": "scroll", "overflow-x": "hidden" } },
            _vm._l(_vm.loaddata, function(item) {
              return _c(
                "v-row",
                {
                  key: item.id,
                  staticStyle: {
                    "margin-bottom": "-15px !important",
                    "font-size": "12px"
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: " btnclr",
                          staticStyle: {
                            cursor: "default",
                            height: "36px !important"
                          }
                        },
                        [_vm._v(_vm._s(item.id))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "padding-1",
                      attrs: { cols: "12", sm: "7" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "grey lighten-3",
                          staticStyle: {
                            border: "1px solid grey",
                            "font-size": "12px",
                            height: "36px"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "9px" },
                              attrs: { cols: "12", sm: "10" }
                            },
                            [
                              item.editable
                                ? _c("v-text-field", {
                                    staticClass: "form-input ",
                                    staticStyle: {
                                      "margin-top": "-6px",
                                      "font-size": "12px"
                                    },
                                    attrs: {
                                      dense: "",
                                      "hide-details": "",
                                      readolny: ""
                                    },
                                    on: { change: _vm.FieldChange },
                                    model: {
                                      value: item.itemname,
                                      callback: function($$v) {
                                        _vm.$set(item, "itemname", $$v)
                                      },
                                      expression: "item.itemname"
                                    }
                                  })
                                : _c("span", [_vm._v(_vm._s(item.itemname))])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { padding: "9px" },
                              attrs: { cols: "12", sm: "2" }
                            },
                            [
                              !item.editable
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.btnDisabledDetail,
                                        text: "",
                                        "x-small": ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editIconClick(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            background: "#eeeded !important",
                                            width: "100%"
                                          },
                                          attrs: { medium: "" }
                                        },
                                        [_vm._v(" mdi-pencil")]
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        "x-small": "",
                                        hidden: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editFieldname(item)
                                        }
                                      }
                                    },
                                    [_vm._v("Save ")]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "padding-0",
                      attrs: { cols: "12", sm: "2" }
                    },
                    [
                      item.button === 1
                        ? _c(
                            "v-btn",
                            {
                              staticClass: " btnClose",
                              staticStyle: {
                                cursor: "default",
                                height: "36px !important"
                              },
                              attrs: { color: "grey ", dark: "" }
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("mdi-close")
                              ])
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: " btnClose",
                              staticStyle: { height: "36px !important" },
                              attrs: { color: "red", dark: "" },
                              on: {
                                click: function($event) {
                                  return _vm.delpopup(item)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("mdi-close")
                              ])
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("input", {
        attrs: { type: "hidden", value: "1", id: "getprevdata " }
      }),
      _c(
        "v-row",
        { staticStyle: { "margin-top": "40px" }, attrs: { col: "12" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "-18px",
                "margin-left": "12px",
                "font-weight": "600",
                color: "red",
                "margin-bottom": "13px",
                width: "100%"
              }
            },
            [
              _c("p", [
                _vm._v(
                  " * " +
                    _vm._s(
                      _vm.$t(
                        "Do not recommend any characters other than english and underbar."
                      )
                    )
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("No digits, No spaces at first of field name.")
                    )
                )
              ])
            ]
          ),
          _c(
            "v-col",
            { staticStyle: { padding: "0" }, attrs: { col: "4" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { disabled: _vm.btnDisabledDetail, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.openAddFieldTab()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Add")) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticStyle: { padding: "0" }, attrs: { col: "4" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { disabled: _vm.btnDisabledDetail, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.openStdIntTab()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Upload File")) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticStyle: { "margin-right": "10px", padding: "0" },
              attrs: { col: "4" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn ml-1",
                  staticStyle: { float: "right" },
                  attrs: {
                    disabled: _vm.btnDisabledDetail,
                    text: "",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.fieldMappingDialog = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Field Mapping")))]
              ),
              _vm.fieldMappingDialog
                ? _c("fieldMappedPopup", {
                    attrs: {
                      fieldMappingDialog: _vm.fieldMappingDialog,
                      getSelectedField: _vm.getfieldlist
                    },
                    on: {
                      "close-popup": function($event) {
                        _vm.fieldMappingDialog = false
                      },
                      fieldMappingload: function($event) {
                        return _vm.refreshFieldname($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "400" },
          model: {
            value: _vm.messageDialog,
            callback: function($$v) {
              _vm.messageDialog = $$v
            },
            expression: "messageDialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-product" }),
                _vm._v(_vm._s(_vm.$t("Add Field")))
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "text-capitalize form-input",
                        attrs: {
                          label: _vm.$t("Field name"),
                          placeholder: _vm.$t("Input field name"),
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          clearable: ""
                        },
                        on: {
                          keydown: [
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "space",
                                  32,
                                  $event.key,
                                  [" ", "Spacebar"]
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                            },
                            _vm.clearTabindex
                          ]
                        },
                        model: {
                          value: _vm.addfieldname,
                          callback: function($$v) {
                            _vm.addfieldname = $$v
                          },
                          expression: "addfieldname"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "", "max-width": "100" },
                      on: { click: _vm.addrow }
                    },
                    [_vm._v(_vm._s(_vm.$t("OK")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "", "max-width": "100" },
                      on: {
                        click: function($event) {
                          _vm.messageDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.delmessageDialog,
            callback: function($$v) {
              _vm.delmessageDialog = $$v
            },
            expression: "delmessageDialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.messageText) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: {
                        text: "",
                        icon: "",
                        disabled: _vm.btnDisabledDetail,
                        "max-width": "100"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteFieldList()
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "", "max-width": "100" },
                      on: {
                        click: function($event) {
                          _vm.delmessageDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.stdIntegrationTab,
            callback: function($$v) {
              _vm.stdIntegrationTab = $$v
            },
            expression: "stdIntegrationTab"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-product" }),
                _vm._v(_vm._s(_vm.$t("Upload Standard Integration")))
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-file-input", {
                        staticClass: "form-file fileinput",
                        attrs: {
                          dense: "",
                          "prepend-icon": "",
                          clearable: "",
                          placeholder: "" + _vm.$t("Select File"),
                          "truncate-length": "50"
                        },
                        on: { keydown: _vm.clearTabindex },
                        model: {
                          value: _vm.stdIntergationfile,
                          callback: function($$v) {
                            _vm.stdIntergationfile = $$v
                          },
                          expression: "stdIntergationfile"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "", "max-width": "100" },
                      on: { click: _vm.saveUploadedStdFile }
                    },
                    [_vm._v(_vm._s(_vm.$t("OK")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "", "max-width": "100" },
                      on: {
                        click: function($event) {
                          _vm.stdIntegrationTab = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }