<template>
<div>
<hr>
<v-row>
  <v-col cols="12" sm="4" style="margin-left:50px;font-size:14px"><h5><b>{{$t('FIELD ID')}}</b></h5></v-col>
  <v-col cols="12" sm="6" style="font-size:14px"><h5><b>{{$t('FIELD NAME')}}</b></h5></v-col>
</v-row>
<hr>
<v-layout column style="height: 460px">
<v-flex  style="overflow-y: scroll;overflow-x: hidden">
<v-row v-for="item in loaddata" :key="item.id"   style="margin-bottom:-15px !important;font-size:12px">
<v-col cols="12" sm="3" ><v-btn style="cursor:default;height: 36px !important" class=" btnclr" >{{item.id}}</v-btn>
</v-col>
<v-col cols="12" sm="7"  class="padding-1">
<v-row class="grey lighten-3" style="border:1px solid grey;font-size:12px;height:36px;">
  <v-col cols="12" sm="10" style="padding: 9px;" >
  <v-text-field
              v-if="item.editable"
              dense
              hide-details
              v-model="item.itemname"
              class="form-input "
              readolny
              style="margin-top:-6px;font-size: 12px;"
               @change="FieldChange"
  ></v-text-field>
  <span  v-else >{{ item.itemname }}</span>

  </v-col>
  <v-col  cols="12" sm="2" style="padding: 9px;">

     <v-btn v-if="!item.editable" @click="editIconClick(item)" :disabled="btnDisabledDetail" text x-small>
    <v-icon medium style="font-size:14px; background: #eeeded !important;width: 100%; "> mdi-pencil</v-icon>
    </v-btn>
    <v-btn v-else @click="editFieldname(item)" text x-small hidden>Save
    </v-btn>

  </v-col>
</v-row>
</v-col>
<v-col class="padding-0"  cols="12" sm="2">
  <v-btn v-if="item.button===1" style="cursor:default;height: 36px !important" class=" btnClose" color="grey "  dark><v-icon dark>mdi-close</v-icon></v-btn>
  <v-btn v-else @click="delpopup(item)" class=" btnClose" style="height: 36px !important" color="red"  dark><v-icon dark>mdi-close</v-icon></v-btn>
</v-col>
</v-row>

</v-flex>
</v-layout>
     <input type="hidden" value='1' id="getprevdata "/>

<v-row col="12" style="margin-top:40px">
  <div style="margin-top:-18px;margin-left:12px;font-weight:600;color:red;margin-bottom: 13px;width: 100%;">
        <p> * {{$t('Do not recommend any characters other than english and underbar.')}}<br>
       {{$t('No digits, No spaces at first of field name.')}}</p>
      </div>
  <v-col col="4" style="padding:0">
    <v-btn  @click="openAddFieldTab()" :disabled="btnDisabledDetail" class="btn" text
      >{{ $t('Add') }}
    </v-btn>

  </v-col>
  <v-col col="4" style="padding:0">
<v-btn  @click="openStdIntTab()" :disabled="btnDisabledDetail" class="btn" text
      >{{ $t('Upload File') }}
    </v-btn>
  </v-col>
  <v-col col="4" style="margin-right:10px;padding:0">
    <v-btn @click="fieldMappingDialog = true" :disabled="btnDisabledDetail" class="btn ml-1" style="float:right"   text dark>{{$t('Field Mapping')}}</v-btn>
    <fieldMappedPopup v-if="fieldMappingDialog" @close-popup="fieldMappingDialog = false" :fieldMappingDialog="fieldMappingDialog" @fieldMappingload="refreshFieldname($event)"   :getSelectedField="getfieldlist"></fieldMappedPopup>
  </v-col>
</v-row>
<!-- add field dialouge -->
<v-dialog v-model="messageDialog" persistent width="400">
  <v-card class="popup add_store_popup" align="center" justify="center">
    <h3 class="page-title-bar"><i class="ico ico-product"></i>{{ $t('Add Field') }}</h3>
<v-row>
  <v-col>
    <v-text-field
            v-model="addfieldname"
            :label="$t('Field name')"
            :placeholder="$t('Input field name')"
            class="text-capitalize form-input"
            outlined
            @keydown.space.prevent
            dense
            hide-details
            clearable
            @keydown="clearTabindex"

    ></v-text-field>
  </v-col>
</v-row>
<v-card-actions class="d-flex justify-center" >
  <v-btn text icon @click="addrow" class="btn" max-width="100">{{$t('OK')}}</v-btn>
  <v-btn text icon @click="messageDialog = false" class="btn" max-width="100">{{$t('Cancel')}}</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
<!-- delete confirmation popup -->
 <v-dialog v-model="delmessageDialog" persistent width="300">
        <v-card class="popup add_store_popup" align="center" justify="center">
          <v-row align="center">
            <v-col>
              <span v-text="messageText"></span>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-center" >
            <v-btn text icon @click="deleteFieldList()" :disabled="btnDisabledDetail" class="btn" max-width="100"
              >Yes</v-btn
            >
            <v-btn text icon @click="delmessageDialog = false" class="btn" max-width="100"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="stdIntegrationTab" persistent width="600">
       <v-card class="popup add_store_popup" align="center" justify="center">
    <h3 class="page-title-bar"><i class="ico ico-product"></i>{{ $t('Upload Standard Integration') }}</h3>
<v-row>
  <v-col>
   <v-file-input
            dense
            prepend-icon
            clearable
            :placeholder="`${$t('Select File')}`"
            class="form-file fileinput"
             v-model="stdIntergationfile"
             truncate-length="50"
             @keydown="clearTabindex"
        >
        </v-file-input>
  </v-col>
</v-row>
<v-card-actions class="d-flex justify-center" >
  <v-btn text icon @click="saveUploadedStdFile" class="btn" max-width="100">{{ $t('OK') }}</v-btn>
  <v-btn text icon @click="stdIntegrationTab = false" class="btn" max-width="100">{{ $t('Cancel') }}</v-btn>
</v-card-actions>
</v-card>
      </v-dialog>
</div>
</template>
<script>
import EventBus from '@/plugins/eventBus'
import fieldMappedPopup from './modal/FieldMapping'
import configs from '@/plugins/configs'

const requests = {
  getfieldListAPI: {
    method: 'GET',
    url: '/common/standardDataMap'
  },
  saveFieldList: {
    method: 'POST',
    url: '/common/standardDataMap'
  },
  stdIntergrationFileupload: {
    method: 'POST',
    url: '/common/standardDataMap/import'
  }

}

export default {
  components: {
    fieldMappedPopup
  },
  data () {
    return {
      fieldMappingDialog: false,
      options: {},
      getfieldlist: [],
      selected: [],
      messageDialog: false,
      stdIntegrationTab: false,
      addfieldname: null,
      checkExistFiledList: [],
      loaddata: [],
      txtItemname: null,
      messageText: '',
      delmessageDialog: '',
      getDeleteItem: '',
      mappedKeyold: '',
      btnDisabledDetail: null,
      preventMultiEdit: false,
      emptyFiedlname: '',
      stdIntergationfile: null,
      mappedFieldList: ['mappedStationCode', 'mappedArticleId', 'mappedArticleName', 'mappedNfc', 'mappedOriginPrice',
        'mappedSalePrice',
        'mappedDiscountPercentage',
        'mappedReservedOne',
        'mappedReservedTwo',
        'mappedReservedThree'

      ],
      reservedList: [
        'reservedOne',
        'reservedTwo',
        'reservedThree',
        'reserveFour',
        'reserveFive',
        'reserveSix',
        'reserveSeven',
        'reserveEight',
        'reserveNine',
        'reserveTen'
      ]

    }
  },
  computed: {
    tableHeaders () {
      return [
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'id'
        },
        {
          text: '',
          sortable: false,
          align: 'left',
          value: 'itemname',
          width: '10%'

        },
        { text: '', sortable: false, align: 'right', value: 'icon', width: '2%' }
      ]
    }

  },

  mounted () {
    this.loadInitialData()
    this.$store.dispatch('auth/getDisabledBtn', '9500').then(flag => {
      this.btnDisabledDetail = flag
    })
  },
  // mounted(){}
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    // load initial data after field mapping save
    refreshFieldname (value) {
      if (value === true) {
        this.loadInitialData()
      }
    },
    loadInitialData () {
      var url = configs.ApiUrl + requests.getfieldListAPI.url
      this.$utils.callAxios(requests.getfieldListAPI.method, url).then(res => {
        this.getfieldlist = res.data
        this.checkExistFiledList = res.data.standardDataMapList
        this.loaddata = []
        const {
          standardDataMapList,
          mappedArticleId,
          mappedStationCode,
          mappedArticleName,
          mappedNfc,
          mappedOriginPrice,
          mappedSalePrice,
          mappedDiscountPercentage,
          mappedReservedOne,
          mappedReservedTwo,
          mappedReservedThree,
          reservedList
        } = this.getfieldlist

        const fieldListArray = [
          mappedArticleId,
          mappedStationCode,
          mappedArticleName,
          mappedNfc,
          mappedOriginPrice,
          mappedSalePrice,
          mappedDiscountPercentage,
          mappedReservedOne,
          mappedReservedTwo,
          mappedReservedThree,
          reservedList
        ]
        standardDataMapList.forEach(function (element, i) {
          if (fieldListArray.includes(element)) {
            this.loaddata.push({
              id: i + 1, itemname: element, editable: false, icon: '', button: 1
            })
          } else {
            this.loaddata.push({
              id: i + 1, itemname: element, editable: false, icon: '', button: 0
            })
          }
        }, this)
      })
    },
    editIconClick (value) {
      if (this.preventMultiEdit) return
      this.mappedKeyold = value.itemname
      value.editable = !value.editable

      this.loaddata.map(function (item) {
        if (item.itemname !== value.itemname && item.itemname !== null) {
          var itemeditableValue = item.editable = false
          return itemeditableValue
        }
      })
    },
    openAddFieldTab () {
      this.messageDialog = true
      this.addfieldname = null
    },
    openStdIntTab () {
      this.stdIntegrationTab = true
      this.stdIntergationfile = null
    },
    // add row
    addrow () {
      var checkfieldlist = this.checkExistFiledList
      if (checkfieldlist.indexOf(this.addfieldname) !== -1) {
        EventBus.$emit('messageAlert', this.$t('Field name is already exist'))
      } else {
        this.loaddata.push({
          id: this.loaddata.length + 1, itemname: this.addfieldname, editable: false, icon: '', button: 0
        })
        this.getfieldlist.standardDataMapList.push(this.addfieldname)
        this.saveFieldlist()
      }
    },
    FieldChange () {
      this.preventMultiEdit = true
    },
    // edit row
    editFieldname (value) {
      if (value.itemname === this.mappedKeyold) {
        value.editable = false
        this.preventMultiEdit = false
        return
      }
      var edititemname = value.itemname
      this.emptyFiedlname = value.itemname
      var checkfieldlist = this.checkExistFiledList

      if (edititemname !== '' && edititemname !== null && edititemname !== 'undefined') {
        if (checkfieldlist.indexOf(value.itemname) !== -1) {
          EventBus.$emit('messageAlert', this.$t('Field name is already exist'))
          return
        }
        this.getfieldlist.standardDataMapList = []
        var editIndex = this.loaddata.map(function (item) {
          return item.id === value.id ? edititemname : item.itemname
        })
        this.mappedFieldList.forEach((element, i) => {
          if (this.getfieldlist[element].indexOf(this.mappedKeyold) !== -1) {
            this.getfieldlist[element] = edititemname
          }
        })
        this.getfieldlist.standardDataMapList = editIndex
      }
      this.saveFieldlist()
    },
    delpopup (item) {
      if (this.preventMultiEdit) return
      if (item.itemname === '' || item.itemname === undefined || item.itemname === null) {
        EventBus.$emit('messageAlert', this.$t('Field name should not be empty'))
        return
      }

      this.messageText = this.$t('Are you sure want to delete the field') + ' ' + item.itemname + '?'
      this.delmessageDialog = true
      this.getDeleteItem = item
    },
    deleteFieldList () {
      var item = this.getDeleteItem
      // FOR API CALL
      var indexvalue = this.getfieldlist.standardDataMapList.indexOf(item.itemname)// get  "car" index
      this.getfieldlist.standardDataMapList.splice(indexvalue, 1)
      // FOR REMOVE FROM UI
      var removeIndex = this.loaddata.map(function (item) { return item.id }).indexOf(item.id)
      this.loaddata.splice(removeIndex, 1)
      this.saveFieldlist()
    },
    // save field name
    saveFieldlist () {
      if (this.getDeleteItem.itemname === '' || this.getDeleteItem.itemname === null || this.getDeleteItem.itemname === undefined) {
        if (this.emptyFiedlname === '' || this.emptyFiedlname === null || this.emptyFiedlname === undefined) {
          if (this.addfieldname === '' || this.addfieldname === null || this.addfieldname === undefined) {
            EventBus.$emit('messageAlert', this.$t('Field name should not be empty'))
            return
          }
        }
      }

      const config = this.getfieldlist
      var url = configs.ApiUrl + requests.saveFieldList.url
      this.addfieldname = null
      this.getDeleteItem = ''
      this.$utils.callAxiosWithBody(requests.saveFieldList.method, url, config).then(res => {
        if (res.status === 200) {
          if (this.delmessageDialog === true) {
            this.delmessageDialog = false
            EventBus.$emit('messageAlert', this.$t('The data has been deleted successfully'))
          } else if (this.messageDialog === true) {
            EventBus.$emit('messageAlert', this.$t('The data has been added successfully'))
            this.messageDialog = false
          } else {
            EventBus.$emit('messageAlert', this.$t('The data has been saved successfully'))
          }
          this.preventMultiEdit = false
          this.loadInitialData()
        } else if (res.status === 400) {
          EventBus.$emit('messageAlert', this.$t('Invalid parameter'))
        } else {
          EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
          if (this.delmessageDialog === true) {
            EventBus.$emit('messageAlert', this.$t('Failed to add data.'))
          } else if (this.messageDialog === true) {
            EventBus.$emit('messageAlert', this.$t('Failed to delete data.'))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
          }
        }
      })
        .catch(error => {
          EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
          console.log(error)
        })
    },
    // save upload std file

    saveUploadedStdFile () {
      if (this.stdIntergationfile !== '' && this.stdIntergationfile !== null && this.stdIntergationfile !== undefined) {
        const formData = new FormData()
        formData.append('file', this.stdIntergationfile)
        var url = configs.ApiUrl + requests.stdIntergrationFileupload.url
        this.$utils.callAxiosWithBody(requests.stdIntergrationFileupload.method, url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
          this.stdIntegrationTab = false
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t('The data has been saved successfully'))
            this.loadInitialData()
            this.stdIntergationfile = null
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
          }
        }).catch(error => {
          console.debug(error)
          EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
        })
      } else {
        EventBus.$emit('messageAlert', this.$t('Please Select File'))
      }
    }

  }
}
</script>
<style scoped>

.tit-section{
background-image: linear-gradient(#ececec,#ececec);
color: black;
}
.btn{
    padding:10px !important
}
.btnclr{
    width: 100%;
    border:1px solid #e8e8e8;
    height:48px !important
}
.btnClose{
     width: 20%;
     height:48px !important
}
::v-deep table.v-table tbody td {
    border: none ;
}
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
    margin-bottom: 20px !important;
}
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
background:grey lighten-2 !important;
}
::v-deep .v-data-table > .v-data-table__wrapper > table {
    margin-top:20px !important
}
::v-deep .v-data-table{
    line-height: 4 !important;
}
.padding-0{
    padding-right:0;
    padding-left:0;
 }
.padding-1{
    padding-right:0;
    padding-bottom: 0;
}
.code input {
  text-transform: uppercase;
}
</style>
